// 引入three.js
import * as THREE from "three";
import {
  AxesHelper,
  PlaneGeometry,
  PointsMaterial,
  ShaderMaterial,
} from "three";
// 导入轨道控制器
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";

// 导入动画库
import gsap, { random } from "gsap";
// 导入dat.gui
// import * as dat from "dat.gui";

const AllModule = (dom: any) => {
  //   const gui = new dat.GUI();
  //    目标: shader 着色器

  // 1.创建场景
  const scene = new THREE.Scene();
  // 2. 创建相机
  const camera = new THREE.PerspectiveCamera(
    90,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );

  //3. 设置相机位置
  camera.position.set(0, 2, 11);
  //   更新相机
  camera.aspect = window.innerWidth / window.innerHeight;
  //   添加相机
  scene.add(camera);

  //   添加坐标轴辅助器
  const axeshelper = new AxesHelper(5);
  // 添加到场景中
  scene.add(axeshelper);

  //添加光源 //会照亮场景里的全部物体（氛围灯），前提是物体是可以接受灯光的，这种灯是无方向的，即不会有阴影。
  const ambient = new THREE.AmbientLight(0xffffff, 0.4);
  const light = new THREE.DirectionalLight(0xffffff, 2); //点光源，color:灯光颜色，intensity:光照强度
  scene.add(ambient);
  light.position.set(200, 300, 400);
  scene.add(light);

  const rgbeLoader = new RGBELoader();

  rgbeLoader.loadAsync("/hdr/4k.hdr").then((texture) => {
    texture.mapping = THREE.EquirectangularReflectionMapping;
    //将加载的材质texture设置给背景和环境
    scene.background = texture;
    scene.environment = texture;
  });

  // 设置加载管理器
  // const loadingManager = new THREE.LoadingManager(
  //   event.onLoad,
  //   event.onProgress,
  //   event.onError
  // );

  // let loader = new GLTFLoader(loadingManager);
  let loader = new GLTFLoader();
  // https://ydd-oss1.oss-cn-hangzhou.aliyuncs.com/dirigible.glb
  // loader.load("/glb/feji.glb", (glft) => {
  loader.load(
    "https://ydd-oss1.oss-cn-hangzhou.aliyuncs.com/dirigible.glb",
    (glft) => {
      // 设置模型的大小
      // glft.scene.scale.set(0.009, 0.009, 0.009);
      // glft.scene.position.set(191.6, 61.556, -550.2);
      scene.add(glft.scene);
      camera.position.set(80, 80, 80);
      renderer.render(scene, camera);
    }
  );

  //   初始化渲染器   alpha: true渲染器透明
  const renderer = new THREE.WebGLRenderer({ alpha: true });
  // const renderer = new THREE.WebGLRenderer();
  // 设置渲染的尺寸大小
  // renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setSize(1920, 600);

  // 开启场景中的阴影贴图
  renderer.shadowMap.enabled = true;
  // renderer.physicallyCorrectLights = true;

  dom.appendChild(renderer.domElement); // 将数据传递到界面

  //   创建轨道控制器
  const controls = new OrbitControls(camera, renderer.domElement);
  // // 设置控制器阻尼 让控制器更有真实效果 必须在动画循环里调用.update()
  controls.enableDamping = true;

  // 设置时钟
  const clock = new THREE.Clock();

  //   根据浏览器每一帧渲染
  const animate = () => {
    const elapsedTime = clock.getElapsedTime();
    controls.update();
    renderer.render(scene, camera);
    // 渲染下一帧的时候就会调用render函数
    requestAnimationFrame(animate);
  };
  animate();

  document.addEventListener("contextmenu", function (event) {
    // event.preventDefault();
  });

  // controls.enableRotate = false; // 禁止左键旋转
  // 禁用右键拖动
  //   controls.mouseButtons = {
  //     LEFT: THREE.MOUSE.ROTATE,
  //     MIDDLE: THREE.MOUSE.PAN,
  //     RIGHT: null, // 设置为null禁用右键拖动
  //   };

  // 鼠标右键按下时，禁止默认行为
  document.addEventListener("mousedown", function (event) {
    if (event.button === 2) {
      // event.preventDefault();
    }
  });
};
export default AllModule;
